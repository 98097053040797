@import "~antd/dist/antd.less";
@import "~antd/es/date-picker/style/index.less";

// Base Scaffolding Variables
@font-family: "Greycliff CF", BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Menu
@menu-popup-bg: #0366eb;

// Layout
@layout-header-background: #fff;
@layout-sider-background: linear-gradient(
  180deg,
  #459cd3 8.33%,
  #448ab7 91.94%
);
@layout-header-padding: 0 25px;

@menu-dark-bg: transparent;
@menu-dark-color: #fff;
@menu-dark-item-active-bg: #18619c;
@menu-dark-highlight-color: #fff;
@menu-dark-selected-item-icon-color: #fff;
@menu-dark-selected-item-text-color: #fff;

// General
@font-variant-base: "normal";
@font-feature-settings-base: "normal";

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: linear-gradient(180deg, #459cd3 8.33%, #448ab7 91.94%);
}
.ant-menu-item.ant-menu-item-selected.menu {
  margin: 0;
  margin-bottom: 0;
}

.ant-menu-item.menu {
  margin: 0;
  margin-bottom: 0px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}
